import React from 'react';
import LoginPage from './screens/login/login';
import SignUpPage from './screens/signUp/signUp';
import SideNavigation from './navigation/SideNavigation';
import './App.css'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreProvider, createStore, action, persist } from "easy-peasy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from './screens/home/home';
import AllergyScreen from './screens/allergy/allergy';
import IngredientScreen from './screens/ingredients/ingredient';
import StoreScreen from './screens/store/store';
import OrganScreen from './screens/organ/organ';

import {ProtectedRoute} from './protectedRoute.js';
import SymptomsScreen from './screens/symptoms/symptoms';
import SubCategoryScreen from './screens/subCategory/subCategory';
import CategoryScreen from './screens/category/category';
import BrandScreen from './screens/brand/brand';
import ProductScreen from './screens/product/product';
import AddProductScreen from './screens/product/addProduct';
import UserScreen from './screens/users/users';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ProductApprovalsScreen from './screens/productApproval/productApproval';
import AddApprovedProductScreen from './screens/productApproval/addApprovedProduct';
import BulkUploadScreen from './screens/bulkUpload/bulkUpload';
import LandingPage from './screens/landingPage/landingPage';
import AboutPage from './screens/landingPage/about';
import ServicesPage from './screens/landingPage/services';
import DownloadPage from './screens/landingPage/download';
import ContactPage from './screens/landingPage/form';
import PartnersPage from './screens/landingPage/partners';
import ExplorePage from './screens/landingPage/explorePage';
import NotPermittedBrandScreen from './screens/notPermittedBrand/notPermittedBrand';
import AboutFiles from './screens/aboutFiles/aboutFiles';
import Disclaimer from './screens/landingPage/disclaimer';
import OptOutAllEmails from './screens/optOut/optOutAllEmails';
import OptOutMarketingEmails from './screens/optOut/optOutMarketingEmails';
import OptOutAppEmails from './screens/optOut/optOutAppEmails';
import SendEmail from './screens/sendEmail/sendEmail';
import Privacy from './screens/landingPage/privacy';

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  palette: {
    secondary: {
      main: "#70a132",
    },
  },
});

const App: React.FC = () => {
  // Your existing code for the App component

  return (
    <div>
      {/* <StoreProvider store={store}> */}
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/Home" element={<HomeScreen />} />
              <Route path="/SignUp" element={<SignUpPage />} />
              <Route path="/Allergy" element={<AllergyScreen />} />
              <Route path="/Ingredient" element={<IngredientScreen />} />
              <Route path="/Store" element={<StoreScreen />} />
              <Route path="/Brand" element={<BrandScreen />} />
              <Route path="/Organ" element={<OrganScreen />} />
              <Route path="/Symptoms" element={<SymptomsScreen />} />
              <Route path="/Category" element={<CategoryScreen />} />
              <Route path="/SubCategory" element={<SubCategoryScreen />} />
              <Route path="/Products" element={<ProductScreen />} />
              <Route path="/Users" element={<UserScreen />} />
              <Route path="/AddProduct" element={<AddProductScreen />} />
              <Route path="/BulkUploads" element={<BulkUploadScreen />} />
              <Route path="/ProductApprovals" element={<ProductApprovalsScreen/>} />
              <Route path="/AddApprovedProduct" element={<AddApprovedProductScreen/>} />
              <Route path="/NotPermittedBrand" element={<NotPermittedBrandScreen />} />
              <Route path="/AboutFiles" element={<AboutFiles />} />
              <Route path="/sendEmail" element={<SendEmail />} />
            </Route>
            <Route path="/Login" element={<LoginPage />} />
             <Route path="/" element={<LandingPage />} />
             <Route path="/About" element={<AboutPage />} />
             <Route path="/Services" element={<ServicesPage />} />
             <Route path="/partners" element={<PartnersPage />} />
             <Route path="/form" element={<ContactPage />} />
             <Route path="/download" element={<DownloadPage />} />
             <Route path="/explore" element={<ExplorePage />} />
             <Route path="/disclaimer" element={<Disclaimer />} />
             <Route path="/privacy" element={<Privacy />} />
             <Route path="/optout/allemails" element={<OptOutAllEmails />} />
             <Route path="/optout/marketingemails" element={<OptOutMarketingEmails />} />
             <Route path="/optout/appemails" element={<OptOutAppEmails />} />
             
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      {/* </StoreProvider> */}
    </div>
  );
};

export default App;
