import React, { Fragment } from "react";
import "./about.scss";
import logo from "../../assets/logo/FoodAllergySupportApp.png";
import Navbar from "./navbar";
import Footer from "./footer";
import { ProductAPI } from "../../api/services";
import { Box } from "@mui/material";


interface Link {
    url: string;
    title: string;
}

const Privacy = () => {
    const lnks: Link[] = [
        { url: "/", title: "Home" },
        { url: "/About", title: "About" },
        { url: "/explore", title: "Explore Allegrow and Where To Start" },
        { url: "/", title: "Allergy Information" },
        { url: "/partners", title: "Partners" },
        { url: "/form", title: "Contact" },
        { url: "/privacy", title: "Privacy" },
    ];

    const [totalProducts, setTotalProducts] = React.useState(0);


    React.useEffect(() => {
        ProductAPI.getProductCount().then((res) => {
            setTotalProducts(res.data);
        });
    }, []);


    const handleScrollToSection = (id: string) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };




    return (
        <>
            <Navbar navbarLinks={lnks} />
            <Box
                sx={{
                    pt: "0px",
                    height: "calc(100vh - 150px)",
                    marginBottom: 10,
                    flex: 1,
                    overflowY: "auto", // Allow vertical scrolling
                    scrollbarWidth: "none", // For Firefox
                    msOverflowStyle: "none", // For Internet Explorer and Edge
                    "&::-webkit-scrollbar": {
                        display: "none", // For Chrome, Safari, and Opera
                    },
                }}
                className="aboutContainer"
            >
                <Box
                    className="top"
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        pt: 5,
                    }}
                >
                    <Box
                        className="top-left"
                        sx={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <h2
                            style={{
                                fontFamily: "margarosa",
                                marginBottom: 0,
                                fontSize: "2em",
                            }}
                        >
                            Privacy
                        </h2>
                    </Box>
                    <Box
                        className="top-right"
                        sx={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img className="homeImage" src={logo} alt="Image" />
                    </Box>
                </Box>
                <Box
                    sx={{
                        textAlign: "justify",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {/* <p style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            MOBILE APPLICATION DISCLAIMER & TERMS OF USE FOR ALLEGROW FOOD
            ALLERGY SUPPORT (PTY) LTD. <br />
            <br />
            INTRODUCTION:
          </p> */}

                    <p style={{ width: "80%", fontSize: "20px" }}>
                        At AlleGrow, accessible from https://allegrow.co.za/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by AlleGrow and how we use it.

                        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

                        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in https://allegrow.co.za/. This policy is not applicable to any information collected offline or via channels other than this website.
                    </p>
                    <p style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
            Information we collect
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
          Information You Provide. When you register to the Site, use it, complete forms, participate in skills tests or surveys, or use the Site as collaborator, and/or register to our affiliate or influencer or similar program, we ask you to provide certain personal information, including a valid email address, Facebook or Google account login details and username. We will also ask you to provide or otherwise collect additional information, such as, your name, profile details, physical address or billing information, telephone number or other contact details, transactional information, payment information (for example, in certain cases we process your payment method and credit card number), taxpayer information and forms, details about other social networks linked accounts, details about your listed gigs, purchases, education, profession and expertise, information and files uploaded by you to the Site, and additional authentication information (such as copies of your government issued ID, passport, or driving license, as permitted by applicable laws and as detailed in our Seller Help Center at “Verifying Your Identity”). We also collect information about or contained in your communications with AlleGrow as well as any of your posts on our blogs or forums and your communication with other users of AlleGrow.

Information We Collect Automatically. We collect information while you access, browse, view or otherwise use the Site. In other words, when you access the Site we collect personal information on your usage of the Site, including transactions and communications with other users through the Site, your searches, the pages you visited, as well as other actions on Site. We also, collect, use and process the information relating to such usage, including geo-location information, IP address, device and connection information, browser information and web-log information, and the URLs of the web pages you’ve viewed or engaged with before or after using the Site. We also collect and process information relating to the use of cookies and similar technologies, as detailed below. We use that information to provide you our services, enhance user experience, personalize your browsing experience as well as monitor the Site for preventing fraud and inappropriate content or behaviour.

Additionally, in order to improve your online experience at AlleGrow, we have implemented impression reporting. While you view our ads, we gather user Global Unique Identifier, HTTP request data like, user agent, IP, host, URL, country/continent from which request made, browser info, device/operating system/operating system version.

The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.

If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.

When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
          </p>
          <p style={{ width: "80%", fontSize: "20px", fontWeight: "bold" }}>
          How Do We Use The Information Collected?
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
          We will use your personal information for the following purposes:
to provide you with quality service and security, to operate the Site, to perform our obligations to you and to develop and improve our service. For example, we use personal information to verify your identity. We also use this information to establish and set up your account, verify or re-issue a password, log your activity, enable your communications with other members, provide customer support and contact you from time to time. The information helps us to develop and improve our services, to understand and analyse our performance as well as your preferences and performance and to customize and personalize our service and enhance your experience (including by making Gig suggestions, ranking search results, etc.).
to ensure marketplace integrity, prevent fraud and maintain a safe and secure marketplace and services. For example, we use personal information to monitor, track and prevent fraudulent activities and other inappropriate activities, monitor content integrity, conduct security investigations and risk assessments, verify or authenticate information provided by you, enforce our Terms of Service and comply with applicable laws. We conduct certain behavioural analytics to achieve the above objectives and in limited cases, if we detect activity that we think poses a risk to the AlleGrow marketplace, other users, our community, or third parties, automated processes may restrict or limit your ability to use AlleGrow. If you would like to challenge any such decision, please contact us at https://allegrow.co.za/.
to contact you, as requested by you or as otherwise approved by you or permitted according to this Policy.
to promote and advertise the Site, our services and the AlleGrowmarketplace. For example, we use the information collected from you for the purpose of sending direct marketing messages (as detailed below), to show you information that may be of interest to you, to organize and facilitate referral programs, contests or other promotional activities or events.
We use your personal information to send you direct marketing communications about our products, services or promotions from AlleGrowthat may be of interest to you or our related services. This may be via email, post, SMS, telephone or targeted online advertisements.
How Long Do We Keep Personal Information?
We apply a general rule of keeping personal information only for as long as is required to fulfil the purpose for which it was collected. However, in some circumstances, we will retain your personal information for longer periods of time. We will retain personal information for the following purposes:
as long as it is necessary and relevant for our operations, e.g. so that we have an accurate record of your dealings with us in the event of any complaints or challenge; and
in relation to personal information from closed accounts to comply with applicable laws, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Site terms and take other actions as permitted by law.
          </p>
                </Box>
            </Box>

            <Footer />
        </>
    );
};

export default Privacy;
