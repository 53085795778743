import React, { Fragment, useState, ChangeEvent, FormEvent } from "react";
import "./form.scss";
import { BiEnvelope } from "react-icons/bi";
import logo from "../../assets/logo/logo.png";
import Navbar from "./navbar";
import Footer from "./footer";
import { toast,ToastContainer } from "react-toastify";
import { UsersAPI } from "../../api/services";
import { Box } from "@mui/material";

interface Link {
  url: string;
  title: string;
}

interface FormData {
  name: string;
  email: string;
  message: string;
}

const Form: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
  });
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/explore", title: "Explore Allegrow and Where To Start" },
    // { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
    { url: "/privacy", title: "Privacy" },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {

    e.preventDefault();
    toast.info("Processing Email", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    

    UsersAPI.contact_us(formData).then(() => {
      toast.success("Email Sent!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }).catch(() => {
      toast.error("An error occured", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })
  };

  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          pt: "0px",
          height: "calc(100vh - 150px)",
          marginBottom: 10,
          flex: 1,
        }}
        className="formContainer"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 5,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontFamily: "margarosa",
                marginBottom: 0,
                fontSize: "2em",
              }}
            >
              Get In Touch
            </h2>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 0,
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
            textAlign: "justify",
          }}
        >
          <p style={{ maxWidth: "80%" }}>
            Connect with our team today for any app related support and queries
          </p>
        </Box>
        <Box
          sx={{
            width: "100%",
            minHeight: "2vh",
            display: "flex",
            flexDirection: "row",
          }}
          className='form-parent'
        >
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center', paddingTop: 10
            }}
            className='form-ui'
          >
            <Box >
              <BiEnvelope
                className="envelope-icon"
                style={{ transform: "translateY(3px)", fontSize: '20px' }}
              />
              <span className="email-text" style={{fontSize:'20px'}}> support@allegrow.co.za</span>
            </Box>
            <img
              src={logo}
              alt=""
              style={{ width: "400px", height: "198px", marginTop: "50px" }}
            />
          </Box>
          <Box className='form' sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: 'center', paddingTop: 10
            }}>
              <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
              <div className="input-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit">SUBMIT</button>
            </form>
            </Box>
        </Box>
      </Box>
      <Footer />
    </>
    // <Fragment>
    //   <Navbar navbarLinks={lnks} />
    //   <div className="formContainer">
    //     <h1>Get in Touch</h1>
    //     <h2>
    //       Connect with our team today for any app related support and queries
    //     </h2>
    //     <div className="formSection">
    //       <div className="contact">
    //         <BiEnvelope className="envelope-icon" />
    //         <span className="email-text"> support@allegrow.co.za</span>
    //         <img src={logo} alt="" />
    //       </div>
    //       <div className="form">
            
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    //   <ToastContainer
    //     position="top-right"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //     theme="light"
    //   />
    // </Fragment>
  );
};

export default Form;
