import React, { Fragment } from "react";
import image from "../../assets/images/download.png";
import appleStore from "../../assets/images/appleStore.png";
import googlePlay from "../../assets/images/googlePlay.png";
import "./download.scss";
import Navbar from "./navbar";
import Footer from "./footer";
import { Box } from "@mui/material";
import { display } from "@mui/system";

interface Link {
  url: string;
  title: string;
}
const Download = () => {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/explore", title: "Explore Allegrow and Where To Start" },
    // { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
    { url: "/privacy", title: "Privacy" },
  ];

  React.useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          pt: "0px",
          height: "calc(100vh - 150px)",
          marginBottom: 10,
          flex: 1,
        }}
        className="downloadContainer"
      >
        <Box
          className="top"
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 5,
          }}
        >
          <Box
            className="top-left"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontFamily: "margarosa",
                marginBottom: 0,
                fontSize: "2em",
              }}
            >
              Food Allergy Support App
            </h2>
          </Box>
        </Box>
        <Box
        className='content-box'
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
          className='left'
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={image} alt="Left Image" />
          </Box>
          <Box
          className='right'
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <p style={{ fontSize: "20px" }}>
                Download the App<br></br> Explore & Enjoy a 7-day Free Trial
                <br></br>
                Thereafter, R50-00 pm
              </p>
            </Box>
            <Box className='buttons' style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
              <a style={{marginRight:'5px'}} href="https://apps.apple.com/us/app/allegrow/id6470016826">
                <img
                  className="downloadImage1"
                  src={appleStore}
                  alt="Right Top Image"
                />
              </a>
              <a style={{marginLeft: '5px'}}href="https://play.google.com/store/apps/details?id=com.drivaticapp.AlleGrow">
                <img
                  className="downloadImage2"
                  src={googlePlay}
                  alt="Right Bottom Image"
                />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
    // <Fragment>
    //   <Navbar navbarLinks={lnks} />
    //   <div className="downloadMainContainer">
    //     <h1>Food Allergy Support App</h1>
    //     <p style={{fontSize: '20px'}}>
    //       Download the App<br></br> Explore & Enjoy a 7-day Free Trial<br></br>
    //       Thereafter, R50-00 pm
    //     </p>
    //     <div className="downloadContainer">
    //       <div className="downloadImages">
    //         <div className="download-left-div">
    //           <img src={image} alt="Left Image" />
    //         </div>
    //         <div className="download-right-div">
    //         {/* const APKUrl = "https://play.google.com/store/apps/details?id=com.drivaticapp.AlleGrow";
    //         const IOSUrl = "https://apps.apple.com/us/app/allegrow/id6470016826"; */}

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </Fragment>
  );
};
export default Download;
