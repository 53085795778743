import { Box, Typography } from "@mui/material";
import * as React from "react";
import Navbar from "../landingPage/navbar";
import Footer from "../landingPage/footer";

interface Link {
  url: string;
  title: string;
}

export default function OptOutAllEmails() {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/explore", title: "Explore Allegrow and Where To Start" },
    // { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
    { url: "/privacy", title: "Privacy" },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const userEmail = urlParams.get("userEmail");
  const userId = urlParams.get("userId"); 
  
  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "100px",
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: 400, fontFamily: "margarosa" }}
        >
          Success!
        </Typography>
        <Typography variant="h6" sx={{ textAlign: "justify" }}>
          You have unsibscribed from all emails/ communications
        </Typography>
      </Box>
      <Footer />
    </>
  );
}
