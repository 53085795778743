import axios from "axios";
import { toast } from "react-toastify";
// import url from './constants';

class API {
    // https://allegrow-api.azurewebsites.net/api/v1/
    //  static url = 'http://127.0.0.1:8000/api/v1/';
    // static url = "http://192.168.1.5:8000/api/v1/";
  static url = 'https://allegrow.co.za/api/v1/';  
}

export class UsersAPI extends API {
    static async signup(data: any) {
        return await axios
            .post(`${this.url}users/signup/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async login(data: any) {
        return await axios
            .post(`${this.url}users/login/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async get_otp(data: any) {
        return await axios
            .post(`${this.url}users/get_otp/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                // throw new Error(error.response.data);
                console.log(error)
            });
    }

    static async forgot_password(data: any) {
        return await axios
            .put(`${this.url}users/forgot_password/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                // throw new Error(error.response.data);
                console.log(error)
            });
    }

    static async getAllUser() {

        return await axios
            .get(`${this.url}users/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async get_Users_All() {

        return await axios
            .get(`${this.url}users/all/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addUser(data: any) {

        return await axios
            .post(`${this.url}users/signup/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getUser(data: any, id: number) {

        return await axios
            .get(`${this.url}users/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getFiles() {

        return await axios
            .get(`${this.url}users/about/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addFiles(data: any) {

        return await axios
            .post(`${this.url}users/about/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addFile() {

        return await axios
            .post(`${this.url}users/about/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteFile(id: any) {

        return await axios
            .delete(`${this.url}users/about/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteUser(id: number) {
        return await axios
            .delete(`${this.url}users/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateUser(data: any, id: number) {

        return await axios
            .put(`${this.url}users/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async contact_us(data: any) {
        return await axios
            .post(`${this.url}users/contact_us/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}



export class AllergyAPI extends API {
    static async getAllAllergies() {

        return await axios
            .get(`${this.url}allergy/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addAllergy(data: any) {

        return await axios
            .post(`${this.url}allergy/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getAllergy(data: any, id: number) {

        return await axios
            .get(`${this.url}allergy/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getAllergyContains() {

        return await axios
            .get(`${this.url}allergy/contains/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteAllergy(id: number) {
        return await axios
            .delete(`${this.url}allergy/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateAllergy(data: any, id: number) {

        return await axios
            .put(`${this.url}allergy/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class IngredientAPI extends API {
    static async getAllIngredients() {

        return await axios
            .get(`${this.url}ingredient/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static async getIngredientsOnly() {

        return await axios
            .get(`${this.url}ingredient/ingredients_only/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static async getAllMayContains() {

        return await axios
            .get(`${this.url}ingredient/maycontain/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addIngredient(data: any) {

        return await axios
            .post(`${this.url}ingredient/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getIngredient(data: any, id: number) {

        return await axios
            .get(`${this.url}ingredient/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteIngredient(id: number) {
        return await axios
            .delete(`${this.url}ingredient/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async removeIngredient(id: number) {
        return await axios
            .delete(`${this.url}ingredient/delete/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateIngredient(data: any, id: number) {

        return await axios
            .put(`${this.url}ingredient/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class StoreAPI extends API {
    static async getAllStore() {

        return await axios
            .get(`${this.url}store/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addStore(data: any) {

        return await axios
            .post(`${this.url}store/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getStore(data: any, id: number) {

        return await axios
            .get(`${this.url}store/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteStore(id: number) {
        return await axios
            .delete(`${this.url}store/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateStore(data: any, id: number) {

        return await axios
            .put(`${this.url}store/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class BrandAPI extends API {
    static async getAllBrands() {

        return await axios
            .get(`${this.url}brand/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addBrand(data: any) {

        return await axios
            .post(`${this.url}brand/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getBrand(data: any, id: number) {

        return await axios
            .get(`${this.url}brand/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteBrand(id: number) {
        return await axios
            .delete(`${this.url}brand/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateBrand(data: any, id: number) {

        return await axios
            .put(`${this.url}brand/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class NotPermittedBrandAPI extends API {
    static async getAllBrands() {

        return await axios
            .get(`${this.url}brand/notpermitted/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addBrand(data: any) {

        return await axios
            .post(`${this.url}brand/notpermitted/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getBrand(data: any, id: number) {

        return await axios
            .get(`${this.url}brand/notpermitted/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteBrand(id: number) {
        return await axios
            .delete(`${this.url}brand/notpermitted/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateBrand(data: any, id: number) {

        return await axios
            .put(`${this.url}brand/notpermitted/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class OrganAPI extends API {
    static async getAllOrgan() {

        return await axios
            .get(`${this.url}organ/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addOrgan(data: any) {

        return await axios
            .post(`${this.url}organ/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getOrgan(data: any, id: number) {

        return await axios
            .get(`${this.url}organ/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteOrgan(id: number) {
        return await axios
            .delete(`${this.url}organ/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateOrgan(data: any, id: number) {

        return await axios
            .put(`${this.url}organ/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class SymptomsAPI extends API {
    static async getAllSymptoms() {

        return await axios
            .get(`${this.url}symptom/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addSymptoms(data: any) {

        return await axios
            .post(`${this.url}symptom/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getSymptoms(data: any, id: number) {

        return await axios
            .get(`${this.url}symptom/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteSymptoms(id: number) {
        return await axios
            .delete(`${this.url}symptom/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateSymptoms(data: any, id: number) {

        return await axios
            .put(`${this.url}symptom/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class ProductAPI extends API {
    static async getAllProductsView() {

        return await axios
            .get(`${this.url}product/getallproduct/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getProductCount() {

        return await axios
            .get(`${this.url}product/count/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getProductById(id: any) {

        return await axios
            .get(`${this.url}product/by_id/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getAllProducts() {

        return await axios
            .get(`${this.url}product/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async resizeImage(data: any) {

        return await axios
            .post(`${this.url}product/resize/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async bulkUpload(data: any) {

        return await axios
            .post(`${this.url}product/upload/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getProductsByName(name: string) {

        return await axios
            .post(`${this.url}product/name/${name}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getProductsByBrand(brandId: any) {

        return await axios
            .get(`${this.url}product/brand/${brandId}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addProduct(data: any) {

        return await axios
            .post(`${this.url}product/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async update_Product(data: any, id: any) {

        return await axios
            .put(`${this.url}product/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async update_Product_No_Changes(id: any) {

        return await axios
            .put(`${this.url}product/no_changes/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async get_Ingredient_Products(id: any) {

        return await axios
            .get(`${this.url}product/per-ingredient/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async get_Store_Products(id: any) {

        return await axios
            .get(`${this.url}product/store/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async get_Category_Products(id: any) {

        return await axios
            .get(`${this.url}product/category/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async get_SubCategory_Products(id: any) {

        return await axios
            .get(`${this.url}product/subcategory/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addProductStores(data: any) {

        return await axios
            .post(`${this.url}product/stores/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async update_ProductStores(data: any) {

        return await axios
            .put(`${this.url}product/stores/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addProductIngredients(data: any) {

        return await axios
            .post(`${this.url}product/ingredients/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addApprovedProduct(data: any) {

        return await axios
            .post(`${this.url}product/approved/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteApprovedProduct(id: number) {

        return await axios
            .delete(`${this.url}product/approved/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addProductMayContainIngredients(data: any) {

        return await axios
            .post(`${this.url}product/maycontain/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async update_ProductIngredients(data: any) {

        return await axios
            .put(`${this.url}product/ingredients/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async update_ProductMayContainIngredients(data: any) {

        return await axios
            .put(`${this.url}product/maycontain/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateProduct(data: any, id: number) {

        return await axios
            .put(`${this.url}product/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async deleteProduct(data: any) {

        return await axios
            .post(`${this.url}product/delete/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getProductsUpdatedList() {
        try {
          const response = await axios({
            url: `${this.url}product/excel/`,
            method: 'GET',
            responseType: 'blob'
          });
      
          const currentDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
          const filename = `ProductsUpdatedList_${currentDate}.xlsx`;
      
          // Check if response is valid
          if (response.data.size === 0) {
            throw new Error('Empty file received');
          }
      
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
      
          // Clean up
          link.remove();
          window.URL.revokeObjectURL(downloadUrl);
      
          toast.success('Products list downloaded successfully');
          return response;
        } catch (error: any) {
          console.error('Download Error:', error);
          
          // Check if it's a blob error response
          if (error.response && error.response.data instanceof Blob) {
            const errorText = await error.response.data.text();
            toast.error(`Export failed: ${errorText}`);
          } else {
            toast.error('Failed to download products list');
          }
          
          throw error;
        }
      }
}

export class CategoryAPI extends API {
    static async getAllCategory() {

        return await axios
            .get(`${this.url}category/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getSubCategories(id: any) {

        return await axios
            .get(`${this.url}category/subcategory/${id}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addCategory(data: any) {

        return await axios
            .post(`${this.url}category/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getCategory(data: any, id: number) {

        return await axios
            .get(`${this.url}category/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteCategory(id: number) {
        return await axios
            .delete(`${this.url}category/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateCategory(data: any, id: number) {

        return await axios
            .put(`${this.url}category/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class SubCategoryAPI extends API {
    static async getAllSubCategory() {

        return await axios
            .get(`${this.url}subcategory/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async addSubCategory(data: any) {

        return await axios
            .post(`${this.url}subcategory/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async getSubCategory(data: any, id: number) {

        return await axios
            .get(`${this.url}subcategory/${id}`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async deleteSubCategory(id: number) {
        return await axios
            .delete(`${this.url}subcategory/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

    static async updateSubCategory(data: any, id: number) {

        return await axios
            .put(`${this.url}subcategory/${id}/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}


export class ProductApprovalsAPI extends API {
    static async getAllProuctApprovals() {
        console.warn("in")
        return await axios
            .get(`${this.url}product_approval/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async productDisApproved(data: any) {
        console.warn("in")
        return await axios
            .post(`${this.url}product_approval/disapproved/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}

export class IngredientContainAPI extends API {
    static async addIngredientContain(data: any) {
        return await axios
            .post(`${this.url}ingredient/contain/`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async updateIngredientContain(id: any, data: any) {
        return await axios
            .put(`${this.url}ingredient/contain/${id}/`, { "contains": data })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async getIngredientContain(id: any) {
        return await axios
            .get(`${this.url}ingredient/contain/${id}/`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
}



export class SyncIngredients extends API {
    static async syncIng(data: any) {

        // console.log("syncing is called")
        return await axios
            .post(`${this.url}product/syncingredients/`, data)
            .then((response) => {
                // console.log("below is response")
                // console.log(response)
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }
    static async productApprovalSyncIng(data: any) {

        // console.log("syncing is called")
        return await axios
            .post(`${this.url}product/productapprovalsyncingredients/`, data)
            .then((response) => {
                // console.log("below is response")
                // console.log(response)
                return response;
            })
            .catch((error) => {
                throw new Error(error.response.data);
            });
    }

}
