// Production
export const url = 'https://allegrow.co.za/api/v1/';
//export const url = 'allegrow.co.za/api/v1/';

//Janu
 export const imageurl = "http://192.168.0.124:8000";
// export const url : string = "http://127.0.0.1:8000/api/v1/";

//Seth 
//  export const imageurl = "http://192.168.88.249:8000";
//  export const url = "http://192.168.88.249:8000/api/v1/";

//Hammad

// export const imageurl = "http://192.168.1.36:8000";
// export const url = "http://192.168.1.5:8000/api/v1/";  


//Thabang
//export const imageurl = "http://192.168.0.126:8000";
// export const url : string = "http://127.0.0.1:8000/api/v1/";